<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <h1 class="titlePage titlePage_textLeft">{{ getUserName }}</h1>

        <breadcrumbs v-if="!inSet"></breadcrumbs>

        <h2 class="titlePage titlePage_subtitle">
          CHOOSE A LOCATION
          <span class="title__img"
                @click="back"
                v-if="inSet"
          >
            <svg-icon name="arrow-left" class=""/>
          </span>
        </h2>
        <Form class="form" autocomplete="off">
          <label class="form__label">
            <Field type="text" class="form__input"
                   placeholder="Type To Search"
                   v-model="models.location"
                   name="location-name"
                   :rules="'uri_safe'"
                   v-on:keydown="allowOnlyUriSafe"
            />
            <ErrorMessage class="form__error"  name="location-name" />
          </label>

          <ul class="searchList">
            <li class="searchList__item"
                v-for="(item, index) in filteredLocations"
                :key="item.id"
                :data-id="index"
                @click="choosenItem(item, index)"
            >
              {{item.name}}
            </li>
          </ul>

          <button type="button" v-if="disableAddLocation" class="btn btn_link">
            <svg-icon name="search"/>
          </button>

          <button v-if="!disableAddLocation" class="btn btn_link" @click.prevent="addLocation"
                  :disabled="disableAddLocation"
          >
            <svg-icon name="plus-circle"/>
            Add location
          </button>
        </Form>
      </div>
    </div>


  </div>
</template>

<script>

  import router from "../../router";
  import breadcrumbs from "./../parts/breadcrumbs";
  import {simpleSearch} from './../../helpers/search';
  import {httpReq} from "../../htttpReq";
  import {mapActions, mapMutations, mapState, mapGetters} from 'vuex';

  export default {
    name: 'SelectLocation',

    props: {
      exchangeData: String,
      inSet: Boolean,
    },
    components: {
      breadcrumbs
    },
    data() {
      return {
        userName: '',
        models: {
          location: '',
          selectedLocation: [],
          defaultLocation: true,
        },
        ex: this.exchangeData,
        locations: [],
        filteredLocations: [],

      };
    },
    watch: {
      'models.location': function () {
        this.filterLocations()
      }
    },
    methods: {
      ...mapMutations('createdOrder', ['updateLocation']),

      ...mapMutations('orderPosition', ['setLocation']),

      ...mapMutations('options', ['setTypeOrigin', 'setAllOptions', 'setItemId']),
      ...mapMutations('app', ['setParentParams', 'set']),
      allowOnlyUriSafe(e) {
          if(e.key === '/') e.preventDefault()
      },
      async addLocation() {
         /* const isValid = await this.$validator.validate()
          if(!isValid) return*/
        // if(this.inSet){
        //   this.$store.commit('createdOrder/updateLocation', this.models.location);
        //   this.$store.dispatch('applySet/postSet');
        //   this.$store.commit('applySet/openModal', false);
        // }

        // else {
          this.$store.commit('types/setLocation', this.models.location);
          // this.$store.commit('createdOrder/updateLocation', this.models.location);
          this.$store.commit('orderPosition/setLocation', this.models.location);
          //this.$parent.exchangeData = 'selectType';
          let path = this.$store.getters['app/orderBuilderPath']('location_id>' + this.models.location);
          this.$store.commit(
            'app/set',
            {
              k: 'breadcrumbs',
              v: [{
                route: {
                  path: currentStep
                },
                name: this.models.location
              }]
            }
          );
          this.$store.commit('orderPosition/nextStep', 'Type')
          // router.push({path: path});
        // }
      },

      choosenItem(target) {
          this.models.location = target;
          this.$store.commit('orderPosition/setLocation', target.name);
      },

      filterLocations() {
        this.filteredLocations = simpleSearch(this.models.location, this.locations);
      },

      back(){
        this.$parent.locations = false;
        this.$parent.color = true;
      },

    },

    computed: {
      ...mapState('app', ['parentParams', 'prevBreadcrumbs']),
      getUserName() {
        return localStorage.getItem('nameUser');
      },

      disableAddLocation() {

          return String(this.models.location).length < 1
              || _.filter(this.filteredLocations, x => {return x.name.toLowerCase() == this.models.location.toLowerCase()}).length > 0;
      },

      ...mapState('orderItem', ['isUpdate']),
        ...mapGetters({
            currentStep: 'orderPosition/currentStep'
        })
    },

    created() {
      httpReq.get('order/location').then(response => {
        this.filteredLocations = response.data.data;
        this.locations = response.data.data;
      }).catch(error => {
        this.$store.commit('errorsModal/openModal', error.response.data.errors);
      })
    }

  };
</script>

<style scoped lang="scss">

  @import "../../../src/assets/scss/utils/vars";

  .form {
    border: 1px solid $navy;
    padding: 20px 20px 4px 20px;
    margin-bottom: 2rem;
    position: relative;
    &__input {
      border: none;
      border-bottom: 2px solid $navy;
      padding-right: 160px;
      padding-left: 0;
    }
    &__label {
      margin: 0;
    }

    .btn {
      position: absolute;
      top: 20px;
      right: 20px;
      color: $green;
      font-family: $bold;
      font-size: $h5;
      text-decoration: none;
      margin: 0;
      padding-right: 0;
    }

    .ui.search.selection.dropdown > input.search {
      padding: 0 !important;
    }
  }

  .ui.selection.dropdown {
    border: none;
    padding: 0 30px 0 0;
  }

  .ui.search.dropdown {
    & > input.search {
      border-bottom: 2px solid $navy !important;
      display: none !important;
      padding: 0;
    }

    .text {
      padding: 0 10px !important;
    }
  }

  @media screen and (max-width: $sm) {
    .form {
      &__input {
        padding-right: 130px;
      }
    }
  }


  .title {
    /*&Page {*/
      /*position: relative;*/
    /*}*/
    &__img {
      right: 0;
      left: auto;
    }
  }

</style>
